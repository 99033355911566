import React from 'react'

import { MealForwardPageLayout } from '@/components/mealforward/mealforwardPageLayout'
import { tempNewsList } from '@/constants/news/mealforward'
import { MealForwardHead } from '@/components/mealforward/mealforwardHead'
import { NewsList } from '@/components/common/news'

export default function MealForwardNews(): JSX.Element {
  return (
    <>
      <MealForwardHead title="株式会社Relicホールディングス" url="company" />
      <MealForwardPageLayout
        title="お知らせ"
        subTitle="NEWS"
        breadcrumbs="GROUP/MEAL FORWARD/NEWS"
        breadcrumbsPath="/group/mealforward/news"
      >
        <NewsList newsList={tempNewsList} isPage />
      </MealForwardPageLayout>
    </>
  )
}
